import { useState } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSun as sunIcon, faMoon as moonIcon } from '@fortawesome/free-solid-svg-icons';
import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  return (
    <div
      className={`flex flex-col items-center justify-center min-h-screen transition-colors duration-300 ${
        darkMode ? 'bg-gray-900 text-gray-200' : 'bg-gray-800 text-white'
      }`}
    >
      {/* Toggle Dark Mode */}
      <button
        className="absolute top-4 right-4 p-2 bg-gray-700 text-white rounded-full hover:bg-gray-600 focus:outline-none"
        onClick={toggleDarkMode}
      >
        {darkMode ? (
          <FontAwesomeIcon icon={sunIcon} size="lg" />
        ) : (
          <FontAwesomeIcon icon={moonIcon} size="lg" />
        )}
      </button>

      {/* Typing effect with Gradient Text */}
      <h1 className="text-6xl font-bold bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent leading-normal">
        <Typewriter
          words={['Coming Soon...', 'Stay Tuned!']}
          loop
          cursor
          cursorStyle="_"
        />
      </h1>

      {/* Social Media Links */}
      <div className="mt-8 flex space-x-6">
        <a href=" https://discord.gg/JHQQvJzTp6" className="hover:opacity-75">
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
        <a href="https://x.com/analyzerbotcr" className="hover:opacity-75">
          <FontAwesomeIcon icon={faXTwitter} size="2x" />
        </a>
      </div>

      {/* Footer */}
      <footer className="absolute bottom-4 text-sm">
        © 2024 AnalyZer | All rights reserved.
      </footer>
    </div>
  );
}

export default App;
